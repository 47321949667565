import React, { Component } from 'react'
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import "../styles/landing.scss"

class ProjectModal extends Component {
    render() {
        return (
            <div className="modal-wrapper">
                <button className="btn modal-close" onClick={this.props.handleModalClose}>
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
                <div className="modal-top">
                    <Img style={{ maxHeight: '85%' }} 
                        imgStyle={{ objectFit: 'contain' }} 
                        outerWrapperClassName="d-flex align-items-center" 
                        className="modal-image" 
                        fluid={this.props.image}/>
                </div>
                <div className="modal-bottom">
                    <p className="modal-company mb-0">{this.props.project.company}</p>
                    <p className="modal-name">{this.props.project.name}</p>
                    <p className="modal-description">{this.props.project.description}</p>
                </div>
            </div>
        ); 
    }
}

export default ProjectModal;