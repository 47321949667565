import React from "react"
import { graphql } from "gatsby"
import ReactModal from 'react-modal'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectCard from "../components/projectcard"
import ProjectModal from "../components/projectmodal"
import projects from "../../content/projects/projects.json"

import "../styles/landing.scss"

ReactModal.setAppElement(`#___gatsby`);

class Home extends React.Component {
    state = { projects: [], projectImages: [], modalOpen: false, selectedProject: {} }

    componentDidMount() {
        this.bindProjects()
        this.bindImages()
    }

    bindImages() {
        let projectImages = {}
        this.props.data.allFile.edges.forEach((img, index) => {
            const name = img.node.childImageSharp.fluid.src.replace(
                /^.*[\\\/]/,
                ""
            )
            projectImages[name] = img.node.childImageSharp.fluid
        })
        this.setState({ projectImages: projectImages })
    }

    bindProjects() {
        let projectSets = []
        projects.forEach((_, index) => {
            if (index % 2) {
                projectSets.push([projects[index - 1], projects[index]])
            } else if (index === projects.length - 1) {
                projectSets.push([projects[index], null])
            }
        })
        this.setState({ projects: projectSets })
    }

    handleProjectClick(project) {
        this.setState({ modalOpen: true, selectedProject: project })
    }

    handleModalClose = event => {
        this.setState({ modalOpen: false })
    }

    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="Home" />
                <div className="d-flex align-items-center" id="landing-top">
                    <div className="container">
                        <div className="landing-block">
                            <h1 className="landing-title">
                                Hey, I’m Brian - A Software <br></br>Engineer
                                from Detroit.
                            </h1>
                            <p className="mt-4">
                                I’ve developed features for millions of users on
                                the Fordpass iOS team, worked on mission
                                planning tools for another planet at NASA, and
                                am currently building Ford’s machine learning
                                platform.{" "}
                            </p>
                            <a
                                className="email-link mt-4"
                                href="mailto:brian.atiyeh@gmail.com">
                                brian.atiyeh@gmail.com
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex justify-content-start"
                    id="featured-work">
                    <div className="container">
                        <h2 className="section-title">Featured Work.</h2>
                        {this.state.projects.map((project, index) => {
                            let content = []
                            content.push(
                                <div className="col-lg-6 col-sm-12 mt-3" onClick={() => this.handleProjectClick(project[0])} key={project[0]["name"]}>
                                    <ProjectCard
                                        id={index}
                                        project={project[0]}
                                        image={
                                            this.state.projectImages[
                                                project[0]["preview_image"]
                                            ]
                                        }>
                                    </ProjectCard>
                                </div>
                            )

                            if (project[1] != null) {
                                content.push(
                                    <div className="col-lg-6 col-sm-12 mt-3" onClick={() => this.handleProjectClick(project[1])} key={project[1]["name"]}>
                                        <ProjectCard
                                            id={index}
                                            project={project[1]}
                                            image={
                                                this.state.projectImages[
                                                    project[1]["preview_image"]
                                                ]
                                            }
                                            onClick={this.handleModalOpen}>
                                        </ProjectCard>
                                    </div>
                                )
                            }

                            return (
                                <div className="row" key={index}>
                                    {content}
                                </div>
                            )
                        })}
                    </div>
                </div>

                <ReactModal
                    isOpen={this.state.modalOpen}
                    onRequestClose={this.handleModalClose}
                    contentLabel="Example Modal In Gatsby"
                    style={modalStyles}>
                    <ProjectModal 
                        project={this.state.selectedProject} 
                        image={this.state.projectImages[this.state.selectedProject["preview_image"]]}
                        handleModalClose={this.handleModalClose}>  
                    </ProjectModal>
                </ReactModal>
            </Layout>
        )
    }
}

export default Home

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 2000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`

const modalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.58)",
        zIndex: 999
    },
    content: {
        maxWidth: "960px",
        maxHeight: "90%",
        margin: "auto",
        padding: 0,
        border: 0,
        zIndex: 999,
        borderRadius: 0
    },
}
