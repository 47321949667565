import React, { Component } from 'react'
import Img from "gatsby-image"

import "../styles/landing.scss"

class ProjectCard extends Component {
    constructor() {
        super()
        this.state = { isHovered: false }
        this.handleHover = this.handleHover.bind(this)
    }

    handleHover(){
        this.setState(prevState => ({
            isHovered: !prevState.isHovered
        }));
    }

    render() {
        return (
            <div className="project-card" 
                id={"project-" + this.props.id} 
                onMouseEnter={this.handleHover} 
                onMouseLeave={this.handleHover}>
                <Img outerWrapperClassName="d-flex align-items-center" 
                    style={{ maxHeight: '85%' }} 
                    imgStyle={{ objectFit: 'contain' }} 
                    className="image" 
                    fluid={this.props.image}/>
                <div className="image-overlay">
                    <div className="image-overlay-text">
                        <p className="project-company mb-0">{this.props.project.company}</p>
                        <p className="project-title">{this.props.project.name}</p>
                    </div>
                </div>
            </div>
        ); 
    }
}

export default ProjectCard;